import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { useOverlay } from '../../context/useOverlay';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Column } from '../UI/Column';
import { NiTeBreedE } from '../UI/NiTeBreedE';
import { Row } from '../UI/Row';
import { Text } from '../UI/Text';
import { UnstyledLink } from '../UI/UnstyledLink';

import CwxLogoWhiteUrl from './CwxLogoWhite.svg';
import HamburgerMenuUrl from './HamburgerMenu.svg';

export function Navbar() {
  return (
    <StyledNav>
      <Mobile />
      <NiTeBreedE>
        <Desktop />
      </NiTeBreedE>
    </StyledNav>
  );
}

function Mobile() {
  const { toggle } = useOverlay();

  function openMenu() {
    toggle();
  }

  return (
    <MobileNavbar>
      <Row>
        <Column breakpoints={{ [Breakpoint.Phone]: 4 }} />
        <Column justify={'center'} breakpoints={{ [Breakpoint.Phone]: 4 }}>
          <UnstyledLink to="/">
            <LogoIcon src={CwxLogoWhiteUrl} alt="crossworx-logo" />
          </UnstyledLink>
        </Column>
        <Column justify={'flex-end'} breakpoints={{ [Breakpoint.Phone]: 4 }}>
          <PhoneMenuIcon src={HamburgerMenuUrl} onClick={openMenu} alt="open-menu" />
        </Column>
      </Row>
    </MobileNavbar>
  );
}

function Desktop() {
  return (
    <DesktopNavbar>
      <LogoContainer to="/">
        <Logo src={CwxLogoWhiteUrl} alt="crossworx-logo" />
      </LogoContainer>
      <List>
        <ListItem>
          <UnstyledLink to="/projecten">
            <Text mode="navbar">Projecten</Text>
          </UnstyledLink>
        </ListItem>
        <ListItem>
          <UnstyledLink to="/diensten">
            <Text mode="navbar">Diensten</Text>
          </UnstyledLink>
        </ListItem>
        <ListItem>
          <UnstyledLink to="/over-ons">
            <Text mode="navbar">Over ons</Text>
          </UnstyledLink>
        </ListItem>
        <ListItem>
          <UnstyledLink to="/contact">
            <Text mode="navbar">Contact</Text>
          </UnstyledLink>
        </ListItem>
      </List>
    </DesktopNavbar>
  );
}

export const LogoIcon = styled.img`
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 4rem;
`;

export const PhoneMenuIcon = styled.img`
  cursor: pointer;
  width: 1.2rem;
`;

export const MobileNavbar = styled.div`
  display: block;

  ${forBreakpoint(Breakpoint.TabletLandscape,
  css`
    display: none;
  `)};
`;


const LogoContainer = styled(UnstyledLink)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNav = styled.nav`
  padding-top: 1.25rem;
  margin-right: 2rem;
  margin-left: 2rem;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  `)};
`;

const Logo = styled.img`
  cursor: pointer;
`;

const DesktopNavbar = styled.div`
  position:relative;
  z-index: 1;
  ${forBreakpoint(Breakpoint.Phone,
  css`
    display: none;
  `)};

  ${forBreakpoint(Breakpoint.TabletLandscape,
    css`
    display: flex;
    color: white;
    justify-content: space-between;
  `)};
`;

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  display: inline-block;
  margin-left: 1.5rem;
    &:hover {
      opacity: 0.7;
    }    
`;