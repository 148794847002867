import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { forwardRef, HTMLAttributes } from 'react';

import { COLORS } from '../../constants';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

type Props = {
  type: 'h1' | 'h2' | 'h3' | 'client' | 'projectQuote';
};

export const Heading = forwardRef<
  HTMLHeadingElement,
  Props & HTMLAttributes<HTMLHeadingElement>
>((props, ref) => {
  return <StyledHeading as={props.type} ref={ref} {...props} />;
});

const types: Record<Props['type'], SerializedStyles> = {
  h1: css`
    font-family: Lato;
    font-weight: 600;

    ${forBreakpoint(Breakpoint.Phone, css`
    `)};

    ${forBreakpoint(Breakpoint.TabletLandscape, css`
      line-height: 2.5rem;
    `)};
  `,
  h2: css`
    font-family: AvenirBold;
    ${forBreakpoint(Breakpoint.Phone, css`
    `)};

    ${forBreakpoint(Breakpoint.TabletLandscape, css`
      font-size: 2rem;
  ` )};
  `,
  h3: css`
    font-family: AvenirRegular;
    ${forBreakpoint(Breakpoint.Phone, css`
    `)};

    ${forBreakpoint(Breakpoint.TabletLandscape, css`
      font-size: 1.8rem;
      font-weight: bold;
  ` )};
  `,
  client: css`
    font-family: AvenirBold;
    ${forBreakpoint(Breakpoint.Phone, css`
      font-size: 2rem;
    `)};

    ${forBreakpoint(Breakpoint.TabletLandscape, css`
      font-size: 3rem;
  ` )};
  `,
  projectQuote: css`
    font-family: AvenirRegular;
    font-weight: 100;
    ${forBreakpoint(Breakpoint.Phone, css`
      font-size: 1.3rem;
    `)};

    ${forBreakpoint(Breakpoint.TabletLandscape, css`
      font-size: 2rem;
  ` )};
  `
};

const StyledHeading = styled.span<Props & { as: string }>`
  position: relative;
  color: ${COLORS.White};
  text-align: left;
  ${props => types[props.type]}
`;
