import { Breakpoint } from './types';

export const BREAKPOINTS: Record<Breakpoint, number> = {
  [Breakpoint.Phone]: 0,
  [Breakpoint.TabletPortrait]: 37.5,
  [Breakpoint.TabletLandscape]: 56.25,
  [Breakpoint.Desktop]: 75,
  [Breakpoint.Wide]: 125,
  [Breakpoint.UltraWide]: 180
};

export enum COLORS {
  Background = '#051A21',
  Green = '#00F2B2',
  DarkGreen = '#A5C7C6',
  White = '#FFFFFF',
  LightGray = '#B1B1B1',
  MediumGray = '#132427',
  DarkGray = '#646D6F',
  BorderGray = '#32474B',
  CwxGreen = '#4B8B71'
}