import styled from '@emotion/styled/macro';
import React from 'react';

type Props = {
  children: any;
};

export function HideOverflow({ children }: Props) {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  overflow: hidden;
`;