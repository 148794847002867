import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ComponentProps } from 'react';

import { Breakpoints } from '../../types';
import { forBreakpoints } from '../../utils/forBreakpoints';

type Props = {
  breakpoints: Breakpoints<number | [number, number]>,
  direction?: 'row' | 'column',
  padding?: number,
  as?: string,
  justify?: 'flex-start' | 'center' | 'flex-end'
};

export function Column(props: Props & ComponentProps<'div'>) {
  return (
    <StyledColumn {...props} />
  );
}

const StyledColumn = styled.div<Props>`
  position: relative;
  flex: 0 0 100%;
  flex-direction: ${(props) => props.direction ? props.direction : 'row'};
  ${(props) => props.justify && css`
    justify-content: ${props.justify};
  `
  }
  ${(props) => props.padding && css`
    padding: 0 ${props.padding}rem;
  `};
  ${(props) => forBreakpoints(props.breakpoints, (value) => {
    const [size, order] = typeof value === 'number' ? [value, 0] : value;
    const width = 100 / 12 * size;

    return width ? css`
      display: flex;
      flex-basis: ${width}%;
      order: ${order};
    ` : css`
      display: none;
      order: ${order};
    `;
  })};
`;
