import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { Breakpoint } from '../../types';
import { ContactInfo } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Column } from '../UI/Column';
import { Content } from '../UI/Content';
import { HideOverflow } from '../UI/HideOverflow';
import { IgnoreMaxWidth } from '../UI/IgnoreMaxWidth';
import { NoSelect } from '../UI/NoSelect';
import { Row } from '../UI/Row';
import { Text } from '../UI/Text';
import { UnstyledLink } from '../UI/UnstyledLink';

import BackToTopIconUrl from './backToTopIcon.svg';
import LogoUrl from './logo.svg';
import GraphicUrl from './logoBottomLoopable.svg';
import PhoneIconUrl from './phoneIcon.svg';

type Props = {
  contactInfo: ContactInfo
};

export function Footer(props: Props) {
  return (
    <footer>
      <Mobile {...props} />
      <StyledDesktop>
        <Desktop {...props} />
      </StyledDesktop>
    </footer>
  );
}

function Mobile({ contactInfo }: Props) {
  return (
    <MobileFooter>
      <Logo src={LogoUrl} alt="logo-dark" />
      <ServicesRow>
        <Column direction={'column'} breakpoints={{ [Breakpoint.Phone]: 6 }}>
          <UnstyledLink to="/">
            <Text mode="footer">Home</Text>
          </UnstyledLink>
          <UnstyledLink to="/projecten">
            <Text mode="footer">Projecten</Text>
          </UnstyledLink>
        </Column>
        <Column direction={'column'} breakpoints={{ [Breakpoint.Phone]: 6 }}>
          <UnstyledLink to="/diensten">
            <Text mode="footer">Over ons</Text>
          </UnstyledLink>
          {/* <UnstyledLink to="/">
            <Text mode="footer">Jobs</Text>
          </UnstyledLink> */}
          <UnstyledLink to="/contact">
            <Text mode="footer">Contact</Text>
          </UnstyledLink>
        </Column>
        <Column direction={'column'} breakpoints={{ [Breakpoint.Phone]: 12 }}>
          <UnstyledLink to="/diensten">
            <Text mode="footer">Diensten</Text>
          </UnstyledLink>
        </Column>
      </ServicesRow>
      <PhoneContainer>
        <a href={`tel:${contactInfo.phone.trim()}`}>
          <PhoneIcon src={PhoneIconUrl} alt="phone-icon" />
          <Text mode="normal">{contactInfo.phone}</Text>
        </a>
      </PhoneContainer>
      <AddressText asDiv mode="footerGray">
        <a href="https://goo.gl/maps/tUpMzMWvPq8gPW2a6">
          {contactInfo.street} {contactInfo.number} | {contactInfo.postal} {contactInfo.city}
        </a>
      </AddressText>
      <SettingsRow>
        <Column direction={'column'} breakpoints={{ [Breakpoint.Phone]: 6 }}>
          <Link to="/privacy-statement">
            <Text mode="footerGray">Privacy statement</Text>
          </Link>
        </Column>
        <Column direction={'column'} breakpoints={{ [Breakpoint.Phone]: 6 }}>
          <Link to="/cookiebeleid">
            <Text mode="footerGray">Cookiebeleid</Text>
          </Link>
        </Column>
      </SettingsRow>
      <BottomGraphic />
    </MobileFooter>
  );
}

function Desktop({ contactInfo }: Props) {
  return (
    <>
      <Content>
        <Row>
          <Column direction={'column'} breakpoints={{ [Breakpoint.TabletLandscape]: 4 }}>
            <PhoneContainer>
              <a href={`tel:${contactInfo.phone.trim()}`}>
                <PhoneIcon src={PhoneIconUrl} alt="phone-icon" />
                <Text mode="normal">{contactInfo.phone}</Text>
              </a>
            </PhoneContainer>
            <AddressText mode="footerGray">
              <a href="https://goo.gl/maps/tUpMzMWvPq8gPW2a6">{contactInfo.street} {contactInfo.number} | {contactInfo.postal} {contactInfo.city}</a>
            </AddressText>
          </Column>
          <Column direction={'column'} breakpoints={{ [Breakpoint.TabletLandscape]: 4 }}>
            <Logo src={LogoUrl} alt="logo-dark" />
          </Column>
          <Column direction={'column'} breakpoints={{ [Breakpoint.TabletLandscape]: 4 }}>
            <ActionContainer>
              <BackToTopButton>
                <Text onClick={() => {
                  window.scrollTo(0, 0);
                }}
                  mode="normal"
                  align="right">
                  Back to top
                  </Text>
                <BackToTopIcon src={BackToTopIconUrl} alt="jump-to-top" />
              </BackToTopButton>
            </ActionContainer>
            <ActionContainer>
              <Link to="/cookiebeleid">
                <Text mode="footerGray">Cookiebeleid</Text>
              </Link>
              <Link to="/privacy-statement">
                <PrivacyPolicyText mode="footerGray">Privacy statement</PrivacyPolicyText>
              </Link>
            </ActionContainer>
          </Column>
        </Row>
      </Content>
      <BottomGraphic />
    </>
  );
}

function BottomGraphic() {
  return (
    <HideOverflow>
      <Graphic />
    </HideOverflow>
  );
}

const PrivacyPolicyText = styled(Text)`
  margin-left: 2rem;
  margin-right: 0.2rem;
`;

const BackToTopButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
  padding-right: 0.2rem;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  :active {
    transform: scale(0.95);
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDesktop = styled.div`
  display: none;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: block;
  `)};
`;

const MobileFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: none;
  `)};
`;

const AddressText = styled(Text)`
  margin-bottom: 3rem;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-bottom: 0rem;
    text-align: left;
  `)};
`;

const PhoneContainer = styled.div`
  display: flex;
  margin-bottom: 0.2rem;
  &:hover {
    opacity: 0.7;
  }    

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-bottom: 0.2rem;
    justify-content: flex-start;
  `)};
`;

const ServicesRow = styled(Row)`
  width: 100%;
  max-width: 15rem;
  text-align: center;
  margin-bottom: 4rem;
  
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: none;
  `)};
`;

const SettingsRow = styled(Row)`
  text-align: center;
  width: 100%;
  margin-bottom: 3.8rem;
  max-width: 15rem;
`;

const BackToTopIcon = styled.img`margin-left: .65rem;`;

const Logo = styled.img`
  ${NoSelect()}
  margin-bottom: 3rem;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-bottom: 0rem;
  `)};
`;

const PhoneIcon = styled.img`
  margin-right: 0.75rem;
`;

const infiniteFooter = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
	100% {
    transform: translate3d(-1367px, 0, 0);
  }
`;

const Graphic = styled.div`
  ${NoSelect()}
  width: 4101px;
  height: 160px;
  background: url(${GraphicUrl}) repeat-x bottom;
  background-position: 50% auto;
  animation: ${infiniteFooter} 200s linear infinite;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-top: 3rem;
  `)};

  ${forBreakpoint(Breakpoint.UltraWide, css`
    width: 8202px;
  `)};
`;