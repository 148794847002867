import styled from '@emotion/styled/macro';
import React from 'react';

type Props = {
  children: any;
};

export function NiTeBreedE({ children }: Props) {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;
