import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

import { NiTeBreedE } from './NiTeBreedE';

type Props = {
  children: any;
};

export function Content({ children }: Props) {
  return (
    <StyledContent>
      <main>
        <NiTeBreedE>
          {children}
        </NiTeBreedE>
      </main>
    </StyledContent>
  );
}

const StyledContent = styled.div`
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  `)};
`;