import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ComponentProps, forwardRef } from 'react';

import { COLORS } from '../../constants';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

type Props = ComponentProps<'button'> & {
  mode: string;
  large?: boolean
  fill?: boolean
  asDiv?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, Props>(({ type = 'button', fill = false, asDiv = false, ...props }, ref) => (
  <>
    {asDiv && (
      <div>
        <StyledButton ref={ref} type={type} data-fill={fill} {...props} />
      </div>
    )}
    {!asDiv && (
      <StyledButton ref={ref} type={type} data-fill={fill} {...props} />
    )}
  </>
));

const modes: Record<Props['mode'], SerializedStyles> = {
  green: css`
    color: black;
    background-color: ${COLORS.Green};
    --focus-color: #dbdbdb;
  `,
  white: css`
    background: white;
  `,
  grey: css`
    background: #1B2B2E;
  `
};

const StyledButton = styled.button<Props>`
  position: relative;
  align-items: center;
  padding: ${props => props.large ? '0.70rem 2rem' : '0.70rem 1.50rem'};
  border-radius:${props => props.large ? '2rem' : '1.5rem'};
  font-family: Lato;
  white-space: nowrap;
  transition: opacity 0.25s ease, transform 0.1s ease;
  outline: none;
  border: none;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
  :active {
    transform: scale(0.95);
  }
  ${(props) => modes[props.mode]};

  ${forBreakpoint(Breakpoint.Phone, css`
    font-size: 1rem;  
`)}
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    font-size: 1.2rem;
`)}
`;