import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import { OverlayProvider } from '../context/OverlayContext';
import { ContactInfo } from '../types';

import { Footer } from './Footer/Footer';
import { GlobalStyles } from './GlobalStyles';
import { Navbar } from './Navbar/Navbar';
import { Overlay } from './Navbar/Overlay';
import { SEO } from './SEO';
import { Content } from './UI/Content';

type Props = {
  children: any;
  includeFooter?: boolean;
};

const contactInfoQuery = graphql`
  query ContactQuery {
    site: sanityContactInfo {
        email
        city
        facebookurl
        instagramurl
        phone
        street
        number
        postal
    }
  }
`;

export function Root({ children, includeFooter = true }: Props) {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta content="IE=edge" http-equiv="X-UA-Compatible" />
        <meta content="True" name="HandheldFriendly" />
        <meta content="320" name="MobileOptimized" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <SEO />

      <StaticQuery
        query={contactInfoQuery}
        render={data => {
          const contactInfo: ContactInfo = data.site;
          return (
            <>
              <OverlayProvider>
                <Overlay phone={contactInfo.phone} />
                <Navbar />
                <Content>
                  {children}
                </Content>
              </OverlayProvider>
              {includeFooter && (
                <Footer contactInfo={contactInfo} />
              )}
            </>
          );
        }}
      />
    </>
  );
}
