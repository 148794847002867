import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ComponentPropsWithoutRef, forwardRef } from 'react';

import { COLORS } from '../../constants';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

type Props = {
  mode: 'normal' | 'footer' | 'footerGray' | 'navbar' | 'client' | 'tag' | 'titleText' | 'projectText',
  align?: 'left' | 'center' | 'right',
  asDiv?: boolean
};

export const Text = forwardRef<
  HTMLSpanElement,
  Props & ComponentPropsWithoutRef<'span'>
>((props, ref) => {
  return (
    <>
      {props.asDiv ?
        <StyledTextAsDiv mode={props.mode} {...props} /> :
        <StyledText mode={props.mode} {...props} ref={ref} />}
    </>
  );
});

const types: Record<Props['mode'], SerializedStyles> = {
  normal: css`
    font-family: Lato;
    font-weight: 400;
  `,
  footer: css`
    font-family: Lato;
    font-size: 1.1rem;
    line-height: 2.3rem;
  `,
  footerGray: css`
    font-family: Lato;
    font-size: 0.9rem;
    line-height: 2.3rem;
    color: ${COLORS.LightGray};
  `,
  navbar: css`
    font-family: Lato;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
  `,
  client: css`
    font-family: AvenirRegular;
    font-size: 1.2rem;
  `,
  tag: css`
    font-family: AvenirRegular;
    font-size: 1rem;
    color: ${COLORS.DarkGray};
  `,
  titleText: css`
    font-family: AvenirRegular;
    font-size: 1.4rem;
  `,
  projectText: css`
    font-family: Lato;
    font-size: 1.1rem;
    line-height: 2rem;

  ${forBreakpoint(Breakpoint.TabletPortrait, css`
      font-size: 1.45rem;
    `)};
  `
};

const StyledTextAsDiv = styled.div<Props>`
  color: ${COLORS.White};
  ${props => (props.align && css`
    text-align: ${props.align}
  `)};
  ${props => types[props.mode]};
`;

const StyledText = styled.span<Props>`
  color: ${COLORS.White};
  ${props => (props.align && css`
    text-align: ${props.align}
  `)};
  ${props => types[props.mode]};
`;
