import { css, Global } from '@emotion/core';
import React from 'react';

import { COLORS } from '../constants';

import './../fonts/typography.css';

export function GlobalStyles() {
  return (
    <Global
      styles={css`
        html,
        body {
          margin: 0;
          padding: 0;
          font-size: 16px;
          background-color: ${COLORS.Background};
          overflow-x: hidden;
          position: relative;
        }
        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }
        a { 
          color: inherit; 
          text-decoration: none;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: normal;
          font-style: normal;
        }
      `}
    />
  );
}
