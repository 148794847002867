import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { Button } from '../UI/Button';
import { Text } from '../UI/Text';

import PhoneIconBlack from './PhoneIconBlack.svg';
import PhoneIconWhite from './PhoneIconWhite.svg';

type Props = {
  buttonTitle: string
  displayText?: boolean
  buttonColor: string
  phoneFontColor: string
  phoneNumber: string
};

export function Contact({
  buttonTitle,
  displayText = true,
  phoneNumber,
  buttonColor,
  phoneFontColor
}: Props) {
  return (
    <ContactContainer>
      <Link to="/contact">
        <StyledButton asDiv mode={buttonColor} large={!displayText}>
          <ButtonText displayText={displayText} mode="navbar">
            {buttonTitle}
          </ButtonText>
        </StyledButton>
      </Link>
      <CallText displayText={displayText} mode="footerGray">
        Of geef ons een belletje
      </CallText>
      <PhoneContainer href={`tel:${phoneNumber}`}>
        <PhoneImg
          src={phoneFontColor === 'black' ? PhoneIconBlack : PhoneIconWhite}
          alt="phone-icon" />
        <PhoneNumber
          phoneFontColor={phoneFontColor}
          mode="footer">
          {phoneNumber}
        </PhoneNumber>
      </PhoneContainer>
    </ContactContainer>
  );
}

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ButtonText = styled(Text) <{ displayText: boolean }>`
  color: black;
  font-size: ${props => props.displayText == false ? '1.5rem' : '1.1rem;'};
  font-weight: normal;
`;

const CallText = styled(Text) <{ displayText }>`
  display: ${props => props.displayText ? 'block' : 'none'};
  margin-top: 2rem;
`;

const PhoneContainer = styled.a`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  vertical-align: center;

  &:hover {
    opacity: 0.7;
  } 
`;


const PhoneNumber = styled(Text) <{ phoneFontColor: string }>`
  color: ${props => props.phoneFontColor == 'black' ? 'black' : 'white'};
  letter-spacing: 1px;
  
`;

const PhoneImg = styled.img`
  margin-right: 1rem;
`;