import styled from '@emotion/styled/macro';
import React, { ComponentProps } from 'react';

type Props = {
  spacing?: number
  direction?: string
};

export function Row({ spacing = 0, direction = 'row', ...props }: Props & ComponentProps<'div'>) {
  return (
    <StyledRow spacing={spacing} direction={direction} {...props} />
  );
}

const StyledRow = styled.div<{ spacing: number, width?: number, direction?: string }>`
  display: flex;
  flex-wrap: wrap;
  margin: -${(props) => props.spacing / 2}rem;
  > * {
    padding: ${(props) => props.spacing / 2}rem;
  }
  flex-direction: ${(props) => props.direction}
`;
