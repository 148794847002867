import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { COLORS } from '../../constants';
import { useOverlay } from '../../context/useOverlay';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Contact } from '../ContactButton/Contact';
import { Column } from '../UI/Column';
import { NoSelect } from '../UI/NoSelect';
import { Row } from '../UI/Row';
import { Text } from '../UI/Text';
import { UnstyledLink } from '../UI/UnstyledLink';

import CloseMenu from './CloseMenu.svg';
import cWhite from './cWhite.svg';
import CwxLogoBlack from './CwxLogoBlack.svg';
import { LogoIcon, MobileNavbar, PhoneMenuIcon } from './Navbar';
import wWhite from './wWhite.svg';
import xWhite from './xWhite.svg';

type Props = {
  phone: string;
};

export function Overlay({ phone }: Props) {
  const { overlay, toggle } = useOverlay();
  function closeMenu() {
    toggle();
  }
  return (
    <>
      {overlay.open && (
        <OverlayContainer>
          <C src={cWhite} alt="back-c" />
          <W src={wWhite} alt="back-w" />
          <X src={xWhite} alt="back-x" />
          <MobileNavbar>
            <Row>
              <Column breakpoints={{ [Breakpoint.Phone]: 4 }} />
              <Column justify={'center'} breakpoints={{ [Breakpoint.Phone]: 4 }}>
                <Link to="/">
                  <LogoIcon src={CwxLogoBlack} alt="crossworx-logo" />
                </Link>
              </Column>
              <Column justify={'flex-end'} breakpoints={{ [Breakpoint.Phone]: 4 }}>
                <PhoneMenuIcon src={CloseMenu} alt="close-menu" onClick={closeMenu} />
              </Column>
            </Row>
          </MobileNavbar>
          <List>
            <StyledUl>
              <ListItem>
                <UnstyledLink to="/projecten">
                  <StyledText mode="normal">Projecten</StyledText>
                </UnstyledLink>
              </ListItem>
              <ListItem>
                <UnstyledLink to="/diensten">
                  <StyledText mode="normal">Diensten</StyledText>
                </UnstyledLink>
              </ListItem>
              <ListItem>
                <UnstyledLink to="/over-ons">
                  <StyledText mode="normal">Over ons</StyledText>
                </UnstyledLink>
              </ListItem>
            </StyledUl>
            <Contact
              buttonColor="white"
              buttonTitle={'Contact'}
              phoneNumber={phone}
              displayText={false}
              phoneFontColor="black"
            />
          </List>
        </OverlayContainer>
      )}
    </>
  );
}

const OverlayContainer = styled.div`
  height: 100vh;
  background: ${COLORS.Green};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  padding-top: 1.25rem;
  padding-right: 2rem;
  padding-left: 2rem;
  overflow: hidden;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: none;
  `)};
`;

const StyledText = styled(Text)`
  font-family: Avenir;
  color: black;
  font-size: 3rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom:10rem;
  text-align: center;
`;

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 2rem;
`;

const ListItem = styled.li`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

function Letter() {
  return css`
    ${NoSelect()}
    
    position: absolute;
    z-index: -1;
    overflow: hidden;
  `;
}

const C = styled.img`
  ${Letter()}
  bottom: 7.5rem;
  left: -8rem;
`;

const W = styled.img`
  ${Letter()}
  bottom: 22rem;
  right: -7rem;
`;

const X = styled.img`
  ${Letter()}
  top: -5rem;
  right: 2rem;
`;