export enum Breakpoint {
  Phone,
  TabletPortrait,
  TabletLandscape,
  Desktop,
  Wide,
  UltraWide
}

export type Breakpoints<T> = Partial<Record<Breakpoint, T>>;

export enum Urls {
  Home = '/home'
}

export type Project = {
  ImagesGallery: any
  Tags: string[]
  Client: string
  Slug: string
};

export type ContactInfo = {
  city: string
  email: string,
  facebookurl?: string,
  instagramurl?: string,
  linkedinurl?: string,
  number: string,
  phone: string,
  postal: string,
  street: string
};

export type Mail = {
  emailAddress: string;
  name: string;
  sender: string;
  interests: string[] | string;
  content: string;
};

export type Interest = {
  interest: string,
  selected: boolean
};

export type Qa = [{
  answer: string;
  question: string;
}];
