import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';


export const UnstyledLink = styled(Link)`
  color: inherit;
  display: inline;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;
